/* FLEXBOX GAP SAFARI FIX  version 3*/
.no-flexbox-gap .main-nav-list li:not(:last-child) {
  margin-right: 3.2rem;
}
.no-flexbox-gap .hero .hero-img-box {
  margin-bottom: 6.4rem;
}
.no-flexbox-gap .features .features-img-box {
  margin-bottom: 6.4rem;
}
.no-flexbox-gap .about div:not(:last-child) {
  margin-bottom: 6.4rem;
}
.no-flexbox-gap .dots .dots__dot:not(:last-child) {
  margin-right: 2.4rem;
}
.no-flexbox-gap .project-text-box {
  margin-bottom: 2.4rem;
}
.no-flexbox-gap .contact .contact-img-box {
  margin-bottom: 6.4rem;
}
.no-flexbox-gap .cta-form div {
  margin-bottom: 2.4rem;
}
.no-flexbox-gap .footer-contact-links {
  margin-bottom: 4.8rem;
}
.no-flexbox-gap .social-links li:not(:last-child) {
  margin-right: 1.2rem;
}
.no-flexbox-gap .footer-nav img {
  margin-right: 0.8rem;
}

/* from 700px */
@media (min-width: 43em) {
  .no-flexbox-gap .footer-contact-links {
    margin-bottom: 0;
    margin-right: 4.8rem;
  }
}

/* from 944px */
@media (min-width: 59em) {
  .no-flexbox-gap .hero .hero-img-box {
    margin-bottom: 0;
    margin-left: 6.4rem;
  }
  .no-flexbox-gap .features .features-img-box {
    margin-bottom: 0;
    margin-right: 6.4rem;
  }
  .no-flexbox-gap .contact .contact-img-box {
    margin-bottom: 0;
    margin-left: 6.4rem;
  }
}

/* from 1440px up*/
@media (min-width: 90em) {
  .no-flexbox-gap .main-nav-list li:not(:last-child) {
    margin-bottom: 0;
    margin-right: 6.4rem;
  }
}

/* 0 to 944px */
@media (max-width: 59em) {
  .no-flexbox-gap .main-nav-list li:not(:last-child) {
    margin-right: 0;
    margin-bottom: 4.8rem;
  }
}

/*
.no-flexbox-gap .main-nav-list li:not(:last-child) {
  margin-right: 4.8rem;
}

.no-flexbox-gap .list-item:not(:last-child) {
  margin-bottom: 1.6rem;
}

.no-flexbox-gap .list-icon:not(:last-child) {
  margin-right: 1.6rem;
}

.no-flexbox-gap .delivered-faces {
  margin-right: 1.6rem;
}

.no-flexbox-gap .meal-attribute:not(:last-child) {
  margin-bottom: 2rem;
}

.no-flexbox-gap .meal-icon {
  margin-right: 1.6rem;
}

.no-flexbox-gap .footer-row div:not(:last-child) {
  margin-right: 6.4rem;
}

.no-flexbox-gap .social-links li:not(:last-child) {
  margin-right: 2.4rem;
}

.no-flexbox-gap .footer-nav li:not(:last-child) {
  margin-bottom: 2.4rem;
}

@media (max-width: 75em) {
  .no-flexbox-gap .main-nav-list li:not(:last-child) {
    margin-right: 3.2rem;
  }
}

@media (max-width: 59em) {
  .no-flexbox-gap .main-nav-list li:not(:last-child) {
    margin-right: 0;
    margin-bottom: 4.8rem;
  }
}
*/
